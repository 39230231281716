import {useState} from 'react'
import styles from './auth.module.scss';
import resetImg from '../../assets/forgot.png'
import { Link } from 'react-router-dom';
import Card from '../../components/card/Card';
import Loader from '../../components/loader/Loader';
import { toast } from "react-toastify";
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase/config';

const Reset = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsLoading(false)
        toast.success("Check your email for a reset link")
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error.message)
      })

  };

  return (
  <>
    { isLoading && <Loader />}
    <section className={`container ${styles.auth}`}>
    <Card>
      <div className={styles.form}>
        <h2>Réinitialiser mot de passe</h2>
        <form onSubmit={resetPassword}>
          <input type="text" 
          placeholder="Email" 
          required
          value={email} 
          onChange={(e) => setEmail(e.target.value)}/>
          <button type="submit" className='--btn --btn-success --btn-block'>Réinitialiser</button>
          <div className={styles.links}>
            <p>
              <Link to='/login'>- Connectez-vous</Link>
            </p>
            <p>
              <Link to='/register'>- Engeristrez-vous</Link>
            </p>
          </div>
        </form>
      </div>
    </Card>
    <div className={styles.img}>
      <img src={resetImg} alt='Reset Password' width="400"/>
    </div>
  </section>
</>
);
}

export default Reset