import {useState, useEffect} from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { FaShoppingCart, FaTimes, FaUserCircle} from "react-icons/fa";
import {HiOutlineMenuAlt3 } from "react-icons/hi";
import { auth } from '../../firebase/config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from "react-toastify";
import logoImg from '../../assets/pap.png';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_ACTIVE_USER, SET_ACTIVE_USER } from '../../redux/slice/authSlice';
import  ShowOnLogin,{ ShowOnLogout }  from '../hiddenLink/hiddenLink'
import { AdminOnlyLink } from '../adminOnlyRoute/AdminOnlyRoute';
import { CALCUTLATE_TOTAL_QUANTITY, selectCartTotalQuantity } from '../../redux/slice/cartSlice';

// this const is to resize the logo automatically
const logo = (
  <div className={styles.logo}>
  <Link to='/'>
    <img src={logoImg} alt="Logo" max-width="100px" />
  </Link>
</div>
);

const activeLink = ({isActive}) => (isActive ? `${styles.active}` : "")

const Header = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [displayName, setdisplayName] = useState("")
  const [scrollPage, setScrollPage] = useState(false)
  const cartTotalQuantity = useSelector(selectCartTotalQuantity)
  useEffect(() => {
    dispatch(CALCUTLATE_TOTAL_QUANTITY())
  }, [])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const fixNavBar = () => {
    if (window.scrollY > 50) {
      setScrollPage(true)
    } else {
      setScrollPage(false)
    }
  }

  window.addEventListener("scroll", fixNavBar)

  // Monitor currently sign in user
  useEffect (() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.displayName == null) {
          const u1 = user.email.substring(0, user.email.indexOf("@"));
          const uName = u1.charAt(0).toUpperCase() + u1.slice(1);
          setdisplayName(uName)
        } else {
          setdisplayName(user.displayName);
        }
        dispatch(SET_ACTIVE_USER({
          email: user.email,
          userName: user.displayName ? user.displayName : displayName,
          userID: user.uid,
        }));
      }else {
        setdisplayName("");
        dispatch(REMOVE_ACTIVE_USER());
      }
    });
  }, [dispatch, displayName])

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  };

  const hideMenu = () => {
    setShowMenu(false)
  };

  const logoutUser = () => {
    // e.preventDefault();
    signOut(auth)
      .then(() => {
        toast.success("Logout Successful...")
        navigate("/")
      })
      .catch((error) => {
        // need to find why there is hook error
        toast.error(error.message)
      })
  }

  const cart = (
    <span className={styles.cart}>
    <Link to="/cart">
      Cart
      <FaShoppingCart size={20} />
      <p>{cartTotalQuantity}</p>
    </Link>
  </span>);

  return (
   <header className={scrollPage ? `${styles.fixed}` : null}>
    <div className={styles.header}>
      {logo}
      <nav className={showMenu ? `${styles["show-nav"]}` : `${styles["hide-nav"]}`}>
        <div className={showMenu ? `${styles["nav-wrapper"]} ${styles["show-nav-wrapper"]}` : `${styles["nav-wrapper"]}`} onClick={hideMenu}></div>
        <ul onClick={hideMenu}>
          <li className={styles["logo-mobile"]}>
            {logo}
            <FaTimes size={22} color="#fff" onClick={hideMenu} />
          </li>
          <li>
            <AdminOnlyLink>
              <Link to='/admin/home'>
                <button className='--btn btn-success'>Admin</button>
              </Link>
            </AdminOnlyLink>
          </li>
          <li>
            <NavLink to="/" className={activeLink}>
              Accueil
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" className={activeLink}>
              Contactez nous
            </NavLink>
          </li>
        </ul>
        <div className={styles["header-right"]} onClick={hideMenu}>
          <span className={styles.links}>
            <ShowOnLogout>
              <NavLink to="/login" className={activeLink}>Connectez-vous</NavLink>
            </ShowOnLogout>
            <ShowOnLogin>
              <a href='#home' style={{ color: 'red' }}>
                <FaUserCircle size={16}/> {displayName}
              </a>
              <NavLink to="/order-history" className={activeLink}>Mes Commandes</NavLink>
              <NavLink to="/" onClick={logoutUser}>Déconnectez-vous</NavLink>
            </ShowOnLogin>
          </span>
          {cart}
        </div>
      </nav>
      <div className={styles["menu-icon"]}>
        {cart}
        <HiOutlineMenuAlt3 size={28} onClick={toggleMenu}/>
      </div>
    </div>
   </header>
  )
}

export default Header
