import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { CALCULATE_SUBTOTAL, CALCUTLATE_TOTAL_QUANTITY, selectCartItems, selectCartTotalAmount } from '../../redux/slice/cartSlice'
import { selectEmail } from '../../redux/slice/authSlice'
import { selectShippingAddress } from '../../redux/slice/checkoutSlice'
import { toast } from 'react-toastify'
import CheckoutForm from '../../components/checkoutForm/CheckoutForm'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const Checkout = () => {
    const [message, setMessage] = useState("Initialisation de la validation...")
    const [clientSecret, setClientSecret] = useState("")

    const cartItems = useSelector(selectCartItems)
    const totalAmount = useSelector(selectCartTotalAmount)
    const customerEmail = useSelector(selectEmail)

    const shippingAddress = useSelector(selectShippingAddress)
    const billingAddress = useSelector(selectShippingAddress)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(CALCULATE_SUBTOTAL())
        dispatch(CALCUTLATE_TOTAL_QUANTITY())
    }, [dispatch, cartItems])

    const description = `Pot-au-Pho Payement reçu de ${customerEmail}, Total: ${totalAmount.toFixed(2)} €`
    
    useEffect(() => {
        fetch("https://eshop.potaupho.be/create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                amount: totalAmount,
                items: cartItems,
                userEmail: customerEmail,
                shipping: shippingAddress,
                billing: billingAddress,
                description,
            }),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json()
                } else {
                    return res.json().then((json) => Promise.reject(json))
                }
            })
            .then((data) => {
                setClientSecret(data.clientSecret)
            })
            .catch((error) => {
                setMessage("Validation rejetée")
                toast.error("Quelque chose n'a pas fonctionné", error)
            })
    }, [])
    
    const appearance = {
        theme: "stripe",
    }
    const options = {
        clientSecret,
        appearance,
    }
    
    return (
        <>
            <section>
                <div className='container'>
                    {!clientSecret && <h3>{message}</h3>}
                </div>
            </section>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </>
    )
}

export default Checkout