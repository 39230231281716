import { useState } from 'react'
import styles from './AddProduct.module.scss'
import Card from '../../card/Card'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage, db } from '../../../firebase/config'
import { toast } from 'react-toastify'
import { Timestamp, addDoc, collection, doc, setDoc } from 'firebase/firestore'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../loader/Loader'
import { selectProducts } from '../../../redux/slice/productSlice'
import { useSelector } from 'react-redux'

const categories = [
  {id: 1, name: "Entree" },
  {id: 2, name: "Soupe" },
  {id: 3, name: "Plat" },
  {id: 4, name: "Boisson" },
  {id: 5, name: "Sandwich" },
]

const initialState = {
  name: "",
  imageURL: "",
  price: 0,
  category: "",
  desc: "",
}

const AddProduct = () => {
  const { id } = useParams();
  const products = useSelector(selectProducts);
  const productEdit = products.find((item) => item.id === id);

  const [product, setProduct] = useState(() => {
    const newState = detectForm(id, { ...initialState }, productEdit)
    return newState;
  })

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function detectForm(id, f1, f2) {
    if (id === "ADD") {
      return f1;
    }
    return f2;
  }

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setProduct({...product, [name]: value})
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `ecompap/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setUploadProgress(progress)
    },
    (error) => {
      toast.error(error.message)
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(
        (downloadURL) => {
          setProduct({...product, imageURL: downloadURL})
          toast.success("Image uploaded successfully !")
        })
    }
    )
  };

  const addProduct = (e) => {
    e.preventDefault()
    setIsLoading(true)
    try{
      const docRef = addDoc(collection(db, "products"), {
        name: product.name,
        imageURL: product.imageURL,
        price: Number(product.price),
        category: product.category,
        desc: product.desc,
        createAt: Timestamp.now().toDate(),
        // activeProduct: true, create a new field to show only active product 
      })
      setIsLoading(false)
      setUploadProgress(0)
      setProduct({ ...initialState })
      toast.success("Product uploaded successfully !")
      navigate("/admin/all-products")
    }catch(error) {
      setIsLoading(false)
      toast.error(error.message)
    }
  }

  const editProduct = (e) => {
    e.preventDefault()
    setIsLoading(true)

    if (product.imageURL !== productEdit.imageURL) {
      const storageRef = ref(storage, productEdit.imageURL);
      deleteObject(storageRef);
    }
    try {
      setDoc(doc(db, "products", id), {
        name: product.name,
        imageURL: product.imageURL,
        price: Number(product.price),
        category: product.category,
        desc: product.desc,
        createAt: product.createAt,
        // activeProduct: product.activeProduct, create a new field to show only active product 
        editedAt: Timestamp.now().toDate(),
      });
      setIsLoading(false)
      toast.success(("Product Edited Successfully"))
      navigate("/admin/all-products")
    }catch(error) {
      setIsLoading(false)
      toast.error(error.message)
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.product}>
        <h2>{detectForm(id, "Add New Product", "Edit Product")}</h2>
        <Card cardClass={styles.card}>
          <form onSubmit={detectForm(id, addProduct, editProduct)}>
            <label>Product name</label>
            <input type='text' 
                placeholder='Product name'
                required 
                name='name'
                value={product.name}
                onChange={(e) => handleInputChange(e)}
            />
            <label>Product image</label>
            <Card cardClass={styles.group}>
              {uploadProgress === 0 ? null : (
              <div className={styles.progress}>
                <div className={styles["progress-bar"]}
                  style={{width: `${uploadProgress}%`}}>
                  {uploadProgress < 100 ? `Uploading ${uploadProgress}%` : ` Upload Complete ${uploadProgress}%`}
                </div>
              </div>
              )}
              <input type='file'
                accept='image/*'
                placeholder='Product image'
                name='image'
                onChange={(e) => handleImageChange(e)}
              />
              {product.imageURL === "" ? null : (
                <input type='text' required name='imageURL' value={product.imageURL} disabled/>
              ) }
            </Card>
            <label>Product price</label>
            <input type='number' 
                placeholder='Product price'
                required name='price'
                value={product.price}
                onChange={(e) => handleInputChange(e)}
            />
            <label>Product Category</label>
            <select required name='category' 
              value={product.category} 
              onChange={(e) => handleInputChange(e)}>
                <option value='' disabled>
                  -- choose product category --
                </option>
                {categories.map((cat) => {
                  return (
                    <option key={cat.id} value={cat.name}>
                      {cat.name}
                    </option>
                  )
                })}
            </select>
            <label>Product Description:</label>
            <textarea name='desc'
                required value={product.desc}
                onChange={(e) => handleInputChange(e)}
                cols='30' rows='10'
            ></textarea>
            <button className='--btn --btn-success'>{detectForm(id, "Save Product", "Edit Product")}</button>
          </form>
        </Card>
      </div>
  </>
  )
}

export default AddProduct