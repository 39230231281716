import React, { useRef } from 'react'
import styles from './Contact.module.scss'
import Card from '../../components/card/Card'
import { FaPhoneAlt, FaEnvelope, FaFacebook} from 'react-icons/fa'
import { GoLocation } from 'react-icons/go'
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify'

const Contact = () => {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_aww8whk', form.current, 'plNxWBm79VDt_qA7Q')
    .then((result) => {
      toast.success("Message bien envoyé")
    }, (error) =>{
      toast.error(error.text)
    })
    e.target.reset()
  }
  return (
    <section>
      <div className={`container ${styles.contact}`}>
        <h2>Contactez nous</h2>
        <div className={styles.section}>
          <form ref={form} onSubmit={sendEmail}>
            <Card cardClass={styles.card}>
              <label>Nom</label>
              <input type='text' name="user_name" placeholder='Prénom et Nom' />
              <label>Email</label>
              <input type='email' name="user_email" placeholder='Email' />
              <label>Sujet</label>
              <input type='text' name="subject" placeholder='Sujet' />
              <label>Message</label>
              <textarea name='message' cols='30' rows='10'></textarea>
              <button className='--btn --btn-success'>Envoyer message</button>
            </Card>
          </form>
          <div className={styles.details}>
            <Card cardClass={styles.card2}>
              <h3>Contacts information</h3>
              <p>Remplissez le formulaire ou liste des différents canaux ci-dessous:</p>
              <div className={styles.icons}>
                <span>
                  <FaPhoneAlt />
                  <p>+32 71 32 20 03</p>
                </span>
                <span>
                  <FaEnvelope/>
                  <p>contact@potaupho.be</p>
                </span>
                <span>
                  <GoLocation /><p>rue  de Montigny, 34 6000 Charleroi</p>
                </span>
                <span>
                  <FaFacebook />
                  <p>https://www.facebook.com/papcharleroi/</p>
                </span>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact