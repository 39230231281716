import React from 'react'
import styels from './Search.module.scss'
import { BiSearch } from 'react-icons/bi'

const Search = ({ value, onChange }) => {
  return (
    <div className={styels.search}>
        <BiSearch size={18} className={styels.icon}/>
        <input type="text" placeholder="Recherche par nom" value={value} onChange={onChange}></input>
    </div>
  )
}

export default Search