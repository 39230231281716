import React, { useState } from 'react'
import styles from './auth.module.scss';
import registerImg from '../../assets/register.png'
import { Link, useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { FaGoogle } from 'react-icons/fa';
import { auth } from '../../firebase/config';
import Loader from '../../components/loader/Loader';
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { selectPreviousURL } from '../../redux/slice/cartSlice';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const previousURL = useSelector(selectPreviousURL)
  const redirectUser = () => {
    if (previousURL.includes("cart")) {
      return navigate("/cart")
    } else {
      navigate("/")
    }
  }

  const registerUser = (e) => {
    e.preventDefault()
    if (password !== cpassword){
      toast.error('Passwords do not match.')
    }
    setIsLoading(true)
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // const user = userCredential.user;
        toast.success("Registration Réussie...")
        redirectUser()
      })
      .catch((error) => {
        toast.error(error.message)
      });
      setIsLoading(false)
  }
  // Login with Google
  const provider = new GoogleAuthProvider();
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        //const user = result.user;
        toast.success("Registration Réussie");
        redirectUser()
      })
      .catch((error) => {
        toast.error(error.message)
      });
  };

  return (
  <>
    { isLoading && <Loader />}
    <section className={`container ${styles.auth}`}>
      <Card>
        <div className={styles.form}>
          <h2>Enregistrez-vous</h2>
          <form onSubmit={registerUser}>
            <input type="text" 
              placeholder="Email" 
              required 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
            />
            <input type="password" 
              placeholder="Password" 
              required 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
            />
            <input type="password"
              placeholder="Confirm Password"
              required
              value={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
            />
            <button type='submit' className='--btn --btn-primary --btn-block'>Engeristrez-vous</button>
	    <button className='--btn --btn-success --btn-block' onClick={signInWithGoogle}><FaGoogle color="#ffff" />  Enregistrez-vous avec Google</button>
            <div className={styles.register}>
              <p>Déjà enregistré ? </p>
              <Link to='/login'>Connectez-vous</Link>
            </div>
          </form>
        </div>
      </Card>
      <div className={styles.img}>
        <img src={registerImg} alt='Login' width="400"/>
      </div>
    </section>
  </>
  );
}

export default Register
