import {useState} from 'react'
import styles from './auth.module.scss';
import loginImg from '../../assets/login.png'
import { Link, useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import Card from '../../components/card/Card';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase/config';
import Loader from '../../components/loader/Loader';
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { selectPreviousURL } from '../../redux/slice/cartSlice';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const previousURL = useSelector(selectPreviousURL)
  const redirectUser = () => {
    if (previousURL.includes("cart")) {
      return navigate("/cart")
    } else {
      navigate("/")
    }
  }

  const loginUser = (e) => {
    e.preventDefault();
    setIsLoading(true)
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // const user = userCredential.user;
        setIsLoading(false)
        toast.success("Login Successful...")
        redirectUser()
      })
      .catch((error) => {
        toast.error(error.message)
        setIsLoading(false)
      })
  }
  
  // Login with Google
  const provider = new GoogleAuthProvider();
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        //const user = result.user;
        toast.success("Login Successful");
        redirectUser()
      })
      .catch((error) => {
        toast.error(error.message)
      });
  };

  return (
  <>
  { isLoading && <Loader />}
  <section className={`container ${styles.auth}`}>
    <div className={styles.img}>
      <img src={loginImg} alt='Login' width="400"/>
    </div>
    <Card>
      <div className={styles.form}>
        <h2>Page de connection</h2>
        <form onSubmit={loginUser}>
          <input type="text" 
              placeholder="Email" 
              required 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
          />
          <input type="password" 
              placeholder="Password" 
              required 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
            />
          <button type='submit' className='--btn --btn-success --btn-block'>Connectez-vous</button>
          <div className={styles.links}>
            <Link to='/reset'>Réinitialiser mot de passe</Link>
          </div>
          <p>-- ou --</p>
          <button className='--btn --btn-success --btn-block' onClick={signInWithGoogle}><FaGoogle color="#ffff" />  Connectez-vous avec Google</button>
          <div className={styles.register}>
            <p>Pas encore Enregistré ?</p>
            <Link to='/register'>Enregistez-vous</Link>
          </div>
        </form>
      </div>
    </Card>
  </section>
  </>
  );
}

export default Login
