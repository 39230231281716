import React, { useState } from 'react'
import styles from './ChangeOrderStatus.module.scss'
import Card from '../../card/Card'
import Loader from '../../loader/Loader'
import { Timestamp, doc, setDoc } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const ChangeOrderStatus = ({order, id}) => {
    const [status, setStatus] = useState("")
    const [isLoading, setIsLoadning] = useState(false)
    const navigate = useNavigate()

    const editOrder = (e, id) => {
      e.preventDefault()
      setIsLoadning(true)

      const orderConfig = {
          userID: order.userID,
          userEmail: order.userEmail,
          orderDate: order.orderDate,
          orderTime: order.orderTime,
          orderAmount: order.orderAmount,
          orderStatus: status,
          cartItems: order.cartItems,
          shippingAddress: order.shippingAddress,
          createAt: order.createAt,
          editedAt: Timestamp.now().toDate(),
      }
      try{
          setDoc(doc(db, "orders", id), orderConfig);
          setIsLoadning(false);
          toast.success("Status changé");
          navigate("/admin/orders");
      }catch(error) {
          toast.error(error.message);
          setIsLoadning(false);
      }
    }
  return (
    <>
    {isLoading && <Loader />}
    <div className={styles.status}>
      <Card cardClass={styles.card}>
        <h4>Mise à jour du status</h4>
        <form onSubmit={(e) => editOrder(e, id)}>
          <pan>
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="" disable>-- Choisir le status --</option>
              <option value="Order placed...">Order placed...</option>
              <option value="Processing...">Processing...</option>
              <option value="Shipped...">Shipped...</option>
              <option value="Delivred">Delivred</option>
            </select>
          </pan>
          <span>
            <button type="submit" className='--btn --btn-success'>Update Status</button>
          </span>
        </form>
      </Card>
    </div>
    </>
  )
}

export default ChangeOrderStatus