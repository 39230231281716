import React from 'react';
import styles from './Footer.module.scss';
import { NavLink } from 'react-router-dom';

const date = new Date()
const year = date.getFullYear()

const Footer = () => {
  return (
    <div className={styles.footer}>
      <span>&copy; {year} All Rights Reversed - <NavLink  to="/conditions"><a> Conditions de vente</a></NavLink></span>
    </div>
  )
}

export default Footer
