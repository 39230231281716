import { useState } from 'react'
import styles from './CheckoutDetails.module.scss'
import Card from '../../components/card/Card'
// import { CountryDropdown } from 'react-country-region-selector'
import { SAVE_BILLING_ADDRESS, SAVE_SHIPPING_ADDRESS } from '../../redux/slice/checkoutSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CheckoutSummary from '../../components/checkoutSummary/CheckoutSummary'


const CheckoutDetails = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    //const [shippingAddress, SetShippingAddress] = useState(companies.name)
    const [selectedCompanyId, SetSelectedCompanyId] = useState('');
    const [selectedTimeframe, SetSelectedTimeframe] = useState('');
    const [formData, SetFormData] = useState({
        name: '',
        phone: '',
        companyName: '',
        line1: '',
        line2: '',
        city: '',
        postal_code: '',
        country: "Belgique",
        timeframe: '',
        remarque: '',
    })
    const companyList = [
        {   id: "1", 
            companyName: "A6K", 
            address: { line1: "Square des Martyrs, 1", line2: "", city: "Charleroi", postal_code: "6000", country: "Belgique"},
            timeframe: ["12h00", "13h00"]
        },
        {
            id: "2", 
            companyName: "Hospital Marie Curie", 
            address: { line1: "Chaussée de Bruxelles, 140", line2: "", city: "Charleroi", postal_code: "6042", country: "Belgique"},
            timeframe: ["11h30", "12h30", "13h30"]
        },
    ]

    const handleInputChange = (e) => {
        const { name, value } = e.target
        SetFormData((prevData) => ({
            ...prevData,
            [name]: value,}))
    }
    const handleDropdownChangeCompany = (e) => {
        const selectedId = e.target.value
        SetSelectedCompanyId(selectedId);
        // Find the selected company object from the list
        const selectedCompany = companyList.find((company) => company.id === selectedId);

        // Update form data based on the selected company
        SetFormData((prevData) => ({
            ...prevData,
            companyName: selectedCompany?.companyName || '',
            ...selectedCompany?.address,
            name: selectedId ? prevData.name : '',
            phone: selectedId ? prevData.phone : '',
           timeframe: selectedTimeframe ? prevData.timeframe : '',
           remarque: selectedId ? prevData.remarque : '',
        }))
        // Set the timeframe options for the selected company
        //SetSelectedTimeframe('');
    }
    const handleTimeframeChange = (e) => {
        const selectedTimeframe = e.target.value;
        SetSelectedTimeframe(selectedTimeframe);
	SetFormData((prevData) => ({
       	    ...prevData,
      	    timeframe: selectedTimeframe,
    	}));
      };


    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(SAVE_SHIPPING_ADDRESS({formData}))
        dispatch(SAVE_BILLING_ADDRESS({formData}))
        navigate("/checkout")
    }

  return (
    <section>
        <div className={`container ${styles.checkout}`}>
            <h2>Validation Details</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <Card cardClass={styles.card}>
                        <h3>Adresse livraison</h3>
                        <button type="submit"className='--btn --btn-success'>Vers Payement</button>
                        <label>Société</label>
                        <select required value={selectedCompanyId} onChange={(e) => handleDropdownChangeCompany(e)}>
                            <option value="">-- Choisir la société --</option>
                            {companyList.map((company) => (
                                <option key={company.id} value={company.id}>
                                {company.companyName}
                                </option>
                            ))}
                        </select>
                        <label>Heure de livraison</label>
                        {selectedCompanyId && (
                        <div>
                            <select required value={selectedTimeframe} onChange={handleTimeframeChange}>
                            <option value="">Choisir l'heure de livraison</option>
                            {companyList.find((company) => company.id === selectedCompanyId)?.timeframe.map((timeframe) => (
                            <option key={timeframe} value={timeframe}>
                                {timeframe}
                            </option>
                            ))}
                            </select>
                        </div>)}
                        <label>Nom</label>
                        <input required
                            type='text' 
                            placeholder='Nom et Prénom' 
                            name='name' 
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                        <label>Téléphone</label>
                        <input required 
                            type='text' 
                            placeholder='Téléphone' 
                            name='phone' 
                            value={formData.phone}
                            onChange={handleInputChange}
                        /> 
                        <label>Rue: 
                            <input type="text" name="line1" value={formData.line1} readOnly/>
                        </label>
                        <label>Ville: 
                            <input type="text" name="city" value={formData.city} readOnly/>
                        </label>
                        <label>Code Postal: 
                            <input type="text" name="postal_code" value={formData.postal_code} readOnly/>
                        </label>
                        <label>Pays: 
                            <input type="text" name="country" value={formData.country} readOnly/>
                        </label>
                    </Card>
                    {/* <Card cardClass={styles.card}>
                        <h3>Facturation  </h3>
                        <label>Nom</label>
                        <input type='text' 
                            placeholder='Nom' 
                            name='name' 
                            value={billingAddress.name}
                            onChange={(e) => handleBilling(e)
                        }/>
                        <label>Société</label>
                        <select required name='company' 
                            value={shippingAddress.company} 
                            onChange={(e) => (e)}>
                            <option value='' disabled>
                                -- choisir la société --
                            </option>
                            {companies.map((comp) => {
                            return (
                            <option key={comp.id} value={comp.name}>
                                {comp.name}
                            </option>
                            )
                        })}
                        </select> */}
                         {/* <label>Adresse 1</label>
                        <input type='text' 
                            placeholder='Adresse 1' 
                            name='line1' 
                            value={billingAddress.line1}
                            onChange={(e) => handleBilling(e)
                        }/>
                         <label>Adresse 2</label>
                        <input type='text' 
                            placeholder='Adresse 2' 
                            name='line2' 
                            value={billingAddress.line2}
                            onChange={(e) => handleBilling(e)
                        }/>
                        <label>Ville</label>
                        <input type='text' 
                            placeholder='Ville' 
                            name='city' 
                            value={billingAddress.city}
                            onChange={(e) => handleBilling(e)
                        }/>
                        <label>Province</label>
                        <input type='text' 
                            placeholder='Province' 
                            name='state' 
                            value={billingAddress.state}
                            onChange={(e) => handleBilling(e)
                        }/>
                        <label>Code postal</label>
                        <input type='text' 
                            placeholder='Code postal' 
                            name='postal_code' 
                            value={billingAddress.postal_code}
                            onChange={(e) => handleBilling(e)
                        }/>
                        <label>Pays</label>
                        <CountryDropdown 
                            className={styles.select}
                            valueType='short'
                            value={billingAddress.country}
                            onChange={(val) => handleBilling({
                                target: {
                                    name:"country",
                                    value: val,
                                }
                            })}
                        /> */}
                        {/* <label>Téléphone</label>
                        <input type='text' 
                            placeholder='Téléphone' 
                            name='phone' 
                            value={billingAddress.phone}
                            onChange={(e) => handleBilling(e)
                        }/>
                    </Card> */}
                </div>
                <div>
                    <Card cardClass={styles.card}>
                        <CheckoutSummary />
                    </Card>
                    <Card cardClass={styles.card}>
                    <h3>Remarque</h3>
			<label></label>
                        <input 
                            type='text' 
			    placeholder='Vos allergènes / avec / sans'
                            name='remarque' 
                            value={formData.remarque}
                            onChange={handleInputChange}
                        />
                    </Card>
                </div>
            </form>
        </div>
        
    </section>
  )
}

export default CheckoutDetails
