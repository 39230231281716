import React from 'react'
import { Link } from 'react-router-dom'

const CheckoutSuccess = () => {
  return (
    <section>
      <div className='container'>
        <h1>Payement réussi</h1>
        <p>Merci pour votre commande</p>
        <br />
          <button className='--btn --btn-success'>
            <Link to="/order-history">Voir Status Commande</Link>
          </button>
      </div>
    </section>
  )
}

export default CheckoutSuccess