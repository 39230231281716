import React, { useEffect } from 'react'
import styles from './Orders.module.scss'
import useFetchColletion from '../../../customHooks/useFetchCollection'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectOrderHistory, STORE_ORDERS } from '../../../redux/slice/orderSlice'
import Loader from '../../loader/Loader'

const Orders = () => {
  const { data, isLoading } = useFetchColletion("orders")
  const orders = useSelector(selectOrderHistory)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(STORE_ORDERS(data))
  }, [dispatch, data])

  const handleClick = (id) => {
    navigate(`/admin/order-details/${id}`)
  }

return (
  <>
    <div className={styles.order}>
      <h2>Historique toutes les commandes</h2>
      {/* <p>Ouvrir une commande pour changer le status</p> */}
      <br />
      <>
        {isLoading && <Loader />}
        <div className={styles.table}>
          {orders.length === 0 ? (
            <p> Pas de commande </p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Date</th>
                  <th># Commande</th>
                  <th>Nom</th>
                  <th>Société</th>
                  <th>Heure</th>
                  <th>Montant</th>
                  {/* <th>Status commande</th> */}
                </tr>
              </thead>
              <tbody>
                { orders.map((order, index) => {
                  const { id, orderDate, orderTime, orderAmount, orderStatus, shippingAddress} = order
                  return(
                    <tr key={id} onClick={() => handleClick(id)}>
                      <td>{index + 1}</td>
                      <td>{orderDate} at {orderTime}</td>
                      <td>{id}</td>
                      <td>{shippingAddress.formData.name}</td>
                      <td>{shippingAddress.formData.companyName}</td>
                      <td>{shippingAddress.formData.timeframe}</td>
                      <td>{orderAmount} €</td>
                      {/* <td><p className={orderStatus !== 'Delivered' ? `${styles.pending}`: `${styles.delivered}`}> */
                        /* {orderStatus}</p></td> */}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      </>
    </div>
  </>
)
}

export default Orders
