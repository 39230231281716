import React from 'react'
import styles from './NotFound.module.scss'
import { Link } from 'react-router-dom'

const NotFound= () => {
  return (
    <div className={styles["not-found"]}>
        <div>
            <h2> Oooops 404 </h2>
            <p>page non trouvées</p>
            <button className='--btn'>
                <Link to="/">
                    &larr; Retour Accueil
                </Link>
            </button>
        </div>
    </div>
  )
}

export default NotFound