import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styles from './ProductDetails.module.scss'
import spinnerImg from '../../../assets/spinner.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_TO_CART, CALCUTLATE_TOTAL_QUANTITY, DECREASE_CART, selectCartItems } from '../../../redux/slice/cartSlice'
import useFetchDocument from '../../../customHooks/useFetchDocument'

const ProductDetails = () => {
  const {id} =useParams()
  const [product, setProduct] = useState(null)
  const disptach = useDispatch()
  const cartItems = useSelector(selectCartItems)
  const { document } = useFetchDocument("products", id)
  const cart = cartItems.find((cart) => cart.id === id)
  const isCartAdded = cartItems.findIndex((cart) => {return cart.id === id})
  // To show comment 
  // const { data } = useFetchCollection("reviews")
  // const filteredReviews = data.filter((review) => review.productID === id)

  useEffect(() => {
    setProduct(document)
  }, [document])

  const addToCart = (product) => {
    disptach(ADD_TO_CART(product))
    disptach(CALCUTLATE_TOTAL_QUANTITY())
  }

  const decreaseCart = (product) => {
    disptach(DECREASE_CART(product))
    disptach(CALCUTLATE_TOTAL_QUANTITY())
  }

  return (
    <section>
      <div className={`container ${styles.product}`}>
        <h2>Détails produit</h2>
        <div>
          <Link to="/#products">&larr; Retour vers les produits</Link>
        </div>
        {product === null ? (
          <img src={spinnerImg} alt="Loading..." style={{width: "50px"}}/>
        ) : (
          <>
            <div className={styles.details}>
              <div className={styles.img}>
                <img src={product.imageURL} alt={product.name} />
              </div>
              <div className={styles.content}>
                <h3>{product.name}</h3>
                <p className={styles.price}>{`${product.price.toFixed(2)} €`}</p>
                <p className={styles.desc}>{product.desc}</p>
                <div className={styles.count}>
                  {isCartAdded < 0 ? null : (
                    <>
                    <button className='--btn'onClick={() => decreaseCart(product)}>-</button>
                    <p><b>{cart.cartQuantity}</b></p>
                    <button className='--btn' onClick={() => addToCart(product)}>+</button>
                    </>
                  )}
                </div>
                <button className='--btn --btn-success' onClick={() => addToCart(product)}>Ajouter au panier</button>
              </div>
            </div>
          </>
        )}
        {/* Activate comment review 
        <Card cardClass={styles.card}>
          <h3>Commentaires du produit</h3>
          <div>
            { filteredReviews.length === 0 ?(
              <p>Pas encore de commentaire</p>
            ) : (
              <>
                {filteredReviews.map((item, index) => {
                  const {rate, review, reviewDate, userName } = item
                  return (
                    <div key= {index} className={styles.review}>
                      <StarsRating value={rate} />
                      <p>{review}</p>
                      <span>
                        <b>{reviewDate}</b>
                      </span>
                      <br />
                      <span>
                        <b>par {userName}</b>
                      </span>
                    </div>
                  )
                })}
              </>
            )}
          </div>
        </Card> */}
      </div>
    </section>
  )
}



export default ProductDetails