import React from 'react'
import styles from './CheckoutSummary.module.scss'
import { useSelector } from 'react-redux'
import { selectCartItems, selectCartTotalAmount, selectCartTotalQuantity } from '../../redux/slice/cartSlice'
import { Link } from 'react-router-dom'
import Card from '../card/Card'


const CheckoutSummary = () => {
    const cartItems = useSelector(selectCartItems)
    const cartTotalAmount = useSelector(selectCartTotalAmount)
    const cartTotalQuantity = useSelector(selectCartTotalQuantity)
  return (
    <div>
        <h3>Résumé panier</h3>
        <div>
            {cartItems.lenght === 0 ? (
            <>
                <p>Panier vide.</p>
                <button className='--btn'>
                    <Link to="/#products">Retour au magasin</Link>
                </button>
            </>
            ) : (
            <>
                <div>
                    <p>
                        <b>{`Produit(s) dans le panier: ${cartTotalQuantity}`}</b>
                    </p>
                    <div className={styles.text}>
                        <h4>Sous Total: </h4>
                        <h3>{cartTotalAmount.toFixed(2)} €</h3>
                    </div>
                    {cartItems.map((item, index) => {
                        const {id, name, price, cartQuantity} = item
                        return (
                            <Card key={id} carClass={styles.card}>
                                <h4>{name}</h4>
                                <p>Quantité: <b>{cartQuantity}</b></p>
                                <p>Prix unitaire: <b>{price.toFixed(2)} €</b></p>
                                <p>Prix total: <b>{(price * cartQuantity).toFixed(2)} €</b></p>
                                <br />
                            </Card>
                        )
                    })}
                </div>
            </>
            )}
        </div>
    </div>
  )
}

export default CheckoutSummary
