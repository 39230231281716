import React, { useEffect, useState } from 'react'
import styles from './OrderDetails.module.scss'
import { Link, useParams } from 'react-router-dom'
import useFetchDocument from '../../customHooks/useFetchDocument'
import spinnerImg from '../../assets/spinner.jpg'

const OrderDetails = () => {
  const { id } = useParams()
  const [order, setOrder] = useState(null)
  const { document } = useFetchDocument("orders", id)

  useEffect(() => {
    setOrder(document)
  }, [document])

  return (
    <section>
      <div className={`contaier ${styles.table}`}>
        <h2>Détails commande</h2>
        <div>
          <Link to="/order-history">&larr; Retours vers les commandes</Link>
        </div>
        <br />
        {order === null ?(
          <img src={spinnerImg} alt="Loading..." style={{width: "50px"}}/>
        ) : (
          <>
          <p>
            <b>Commande ID</b> {order.id}
          </p>
          <p>
            <b>Montant de la commande</b> {order.orderAmount.toFixed(2)}
          </p>
          <p>
            <b>Status de la commande</b> {order.orderStatus}
          </p>
          <br />
          <table>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Produit</th>
                <th>Prix</th>
                <th>Quantité</th>
                <th>Total</th>
                {/* To activate a comment on a product
                <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {order.cartItems.map((cart, index) => {
                const { id, name, price, imageURL, cartQuantity } = cart
                return (
                  <tr key={id}>
                    <td><b>{index + 1}</b></td>
                    <td>
                      <p><b>{name}</b></p>
                      <img src={imageURL} alt={name} style={{width: "50px"}}/>
                    </td>
                    <td>{price.toFixed(2)}</td>
                    <td>{cartQuantity}</td>
                    <td>{(price * cartQuantity).toFixed(2)} €</td>
                    {/* To activate the comment on a product
                    <td className={styles.icons}>
                      <Link to={`/review-products/${id}`}>
                        <button className='--btn btn-success'>Commenter le produit</button>
                      </Link>
                    </td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
          </>
        )}
      </div>
    </section>
  )
}

export default OrderDetails
