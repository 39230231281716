import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "ecompap-2fe56.firebaseapp.com",
  projectId: "ecompap-2fe56",
  storageBucket: "ecompap-2fe56.appspot.com",
  messagingSenderId: "765333822778",
  appId: "1:765333822778:web:24b6f1e29bafdf32770403"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app