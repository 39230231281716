import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
    cartItems: localStorage.getItem("cartItems") 
        ? JSON.parse(localStorage.getItem("cartItems"))
        : [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    previousURL: "",
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ADD_TO_CART(state, action) {
        // console.log(action.payload);
        const productIndex = state.cartItems.findIndex(
            (item) => item.id === action.payload.id)
        if (productIndex >= 0) {
            // Item already exists in the cart, Increase the cartQuantity
            state.cartItems[productIndex].cartQuantity += 1;
            toast.info(`${action.payload.name } ajouté au panier`, {position: "top-left"})
        } else {
            // Item doesn't exist in the cart; Add itme in the cart
            const tempProduct = {...action.payload, cartQuantity: 1}
            state.cartItems.push(tempProduct)
            toast.success(`${action.payload.name } ajouté au panier`, {position: "top-left"})
        }
        // Save cart to localstorage
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
    },

    DECREASE_CART(state, action) {
        // console.log(action.payload)
        const productIndex = state.cartItems.findIndex(
            (item) => item.id === action.payload.id)
        if (state.cartItems[productIndex].cartQuantity > 1) {
            state.cartItems[productIndex].cartQuantity -= 1
            toast.warning(`${action.payload.name } retiré du panier`, {position: "top-left"})
        } else if (state.cartItems[productIndex].cartQuantity === 1) {
            const newCartItem = state.cartItems.filter((item) => item.id !== action.payload.id)
            state.cartItems = newCartItem
            toast.warning(`${action.payload.name } retiré au panier`, {position: "top-left"})
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
    },

    REMOVE_FROM_CART(state, action) {
        // console.log(action.payload)
        const newCartItem = state.cartItems.filter((item) => item.id !== action.payload.id)
        state.cartItems = newCartItem
        toast.error(`${action.payload.name } retiré au panier`, {position: "top-left"})
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
      },

    CLEAR_CART(state, action) {
       state.cartItems = [] 
       toast.error(`Panier vidé`, {position: "top-left"})
       localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
      },

    CALCULATE_SUBTOTAL(state) {
        // console.log(action.payload)
        const array = []
        state.cartItems.map((item) => {
            const {price, cartQuantity} = item
            const cartItemAmount = price * cartQuantity
            return array.push(cartItemAmount)
        })
        const totalAmount = array.reduce((a, b) => { return a + b; }, 0)
        // state.cartTotalAmount = parseFloat(totalAmount.toFixed(2))
        state.cartTotalAmount = totalAmount
      },

    CALCUTLATE_TOTAL_QUANTITY(state, action) {
        // console.log(action.payload)
        const array = []
        state.cartItems.map((item) => {
            const {cartQuantity} = item
            const quantity = cartQuantity
            return array.push(quantity)
        })
        const totalQuantity = array.reduce((a, b) => { return a + b; }, 0)
        state.cartTotalQuantity = totalQuantity
      },

    SAVE_URL(state, action) {
        state.previousURL = action.payload

      }
    }
});

export const { ADD_TO_CART, DECREASE_CART, REMOVE_FROM_CART, CLEAR_CART, CALCULATE_SUBTOTAL, CALCUTLATE_TOTAL_QUANTITY, SAVE_URL } = cartSlice.actions
export const selectCartItems = (state) => state.cart.cartItems
export const selectCartTotalQuantity = (state) => state.cart.cartTotalQuantity
export const selectCartTotalAmount = (state) => state.cart.cartTotalAmount
export const selectPreviousURL = (state) => state.cart.previousURL
export default cartSlice.reducer
