import React, { useEffect } from 'react'
import styles from './Cart.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_TO_CART, CALCULATE_SUBTOTAL, CALCUTLATE_TOTAL_QUANTITY, CLEAR_CART, DECREASE_CART, REMOVE_FROM_CART, SAVE_URL, selectCartItems, selectCartTotalAmount, selectCartTotalQuantity } from '../../redux/slice/cartSlice'
import { Link, useNavigate } from 'react-router-dom'
import { FaTrashAlt } from 'react-icons/fa'
import Card from '../../components/card/Card' 
import { selectIsLoggedIn } from '../../redux/slice/authSlice'

const Cart = () => {
  const cartItems = useSelector(selectCartItems)
  const cartTotalAmount = useSelector(selectCartTotalAmount)
  const cartTotalQuantity = useSelector(selectCartTotalQuantity)
  const distpach = useDispatch()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const navigate = useNavigate()

  const increaseCart = (cart) => {
    distpach(ADD_TO_CART(cart))
  }

  const decreaseCart = (cart) => {
    distpach(DECREASE_CART(cart))
  }

  const removeFromCart = (cart) => {
    distpach(REMOVE_FROM_CART(cart))
  }

  const clearCart = () => {
    distpach(CLEAR_CART())
  }
  useEffect(() => {
    distpach(CALCULATE_SUBTOTAL())
    distpach(CALCUTLATE_TOTAL_QUANTITY())
    distpach(SAVE_URL(""))
  }, [cartItems, distpach])

  const url = window.location.href
  const checkout = () => {
    if (isLoggedIn) {
      navigate("/checkout-details")
    } else {
      distpach(SAVE_URL(url))
      navigate("/login")
    }
  }

  return (
    <section>
      <div className={`container ${styles.table}`}>
        <h2>Votre panier</h2>
        {cartItems.lenght === 0 ? (
          <>
            <p>Panier vide</p>
            <br />
            <div>
              <Link to="/#products">&larr; Continuer les achats</Link>
            </div>
          </>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>id</th>
                  <th>Produit</th>
                  <th>Prix</th>
                  <th>Quantité</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((cart, index) => {
                  const {id, name, price, imageURL, cartQuantity} = cart;
                  return (
                    <tr key={id}>
                      <td>{index + 1}</td>
                      <td>
                        <p><b>{name}</b></p>
                        <img src={imageURL} alt={name} style={{width: "50px"}}/>
                      </td>
                      <td>{price.toFixed(2)} €</td>
                      <td>
                        <div className={styles.count}>
                          <button className="--btn" onClick={()=> decreaseCart(cart)}>-</button>
                          <p><b>{cartQuantity}</b></p>
                          <button className="--btn" onClick={() => increaseCart(cart)}>+</button>
                        </div>
                      </td>
                      <td>{(price * cartQuantity).toFixed(2)} €</td>
                      <td className={styles.icons}>
                        <FaTrashAlt size={15} color="red" onClick={()=> removeFromCart(cart)}/>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className={styles.summary}>
              <button className='--btn --btn-danger' onClick={clearCart}>Effacer Panier</button>
              <div className={styles.checkout}>
                <div>
                  <Link to="/#products">&larr; Continuer les achats</Link>
                </div>
                <br />
                <Card cardClass={styles.card}>
                  <p>{`Produit(s): ${cartTotalQuantity}`}</p>
                  <div className={styles.text}>
                    <h4>Sous Total</h4>
                    <h3>{`${cartTotalAmount.toFixed(2)} €`}</h3>
                  </div>
                  <p>Taxes calculé à la validation</p>
                  <button className="--btn --btn-success" onClick={checkout}>Valider</button>
                </Card>
              </div>
            </div>
            </>
        )}
      </div>
    </section>
  )
}

export default Cart
