import React, { useEffect } from 'react'
import styles from './Home.module.scss'
import InfoBox from '../../infoBox/InfoBox'
import { AiFillEuroCircle } from "react-icons/ai"
import { BsCart4 } from 'react-icons/bs'
import { FaCartArrowDown } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { STORE_PRODUCTS, selectProducts } from '../../../redux/slice/productSlice'
import { selectTotalOrderAmount, selectOrderHistory, STORE_ORDERS, CALC_TOTAL_ORDER_AMOUNT } from '../../../redux/slice/orderSlice'
import useFectCollection from '../../../customHooks/useFetchCollection'

// Icons
const earningIcon = <AiFillEuroCircle size={30} color="#72b678" /> 
const productIcon = <BsCart4 size={30} color="#72b678" /> 
const ordersIcon = <FaCartArrowDown size={30} color="#72b678" /> 

const Home = () => {
  const products = useSelector(selectProducts)
  const orders = useSelector(selectOrderHistory)
  const totalOrderAmount = useSelector(selectTotalOrderAmount)
  const fbProducts = useFectCollection("products") 
  const {data} = useFectCollection("orders")
  const distpach = useDispatch()
  useEffect(() => {
    distpach(STORE_PRODUCTS({
      products: fbProducts.data,
    }))
    distpach(STORE_ORDERS(data))
    distpach(CALC_TOTAL_ORDER_AMOUNT())
  }, [distpach, data, fbProducts])

  return (
    <div className={styles.home}>
      <h2>Admin Home </h2>
      <div className={styles["info-box"]}>
        <InfoBox cardClass={`${styles.card} ${styles.card1}`}
        title = {"Earnings"}
        count = {totalOrderAmount}
        icon = {earningIcon}
        />
        <InfoBox cardClass={`${styles.card} ${styles.card2}`}
        title = {"Products"}
        count = {products.length}
        icon = {productIcon}
        />
        <InfoBox cardClass={`${styles.card} ${styles.card3}`}
        title = {"Orders"}
        count = {orders.length}
        icon = {ordersIcon}
        />
      </div>
    </div>
  )
}

export default Home
