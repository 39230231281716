import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import styles from './CheckoutForm.module.scss'
import CheckoutSummary from '../checkoutSummary/CheckoutSummary'
import spinnerImg from '../../assets/spinner.jpg'
import Card from '../card/Card'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_CART, selectCartItems, selectCartTotalAmount } from '../../redux/slice/cartSlice'
import { selectEmail, selectUserID } from '../../redux/slice/authSlice'
import { selectShippingAddress } from '../../redux/slice/checkoutSlice'
import { Timestamp, addDoc, collection } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useNavigate } from 'react-router-dom'

const CheckoutForm = () => {
    const stripe = useStripe()
    const elements = useElements()

    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userID = useSelector(selectUserID)
    const userEmail = useSelector(selectEmail)
    const cartItems = useSelector(selectCartItems)
    const cartTotalAmount = useSelector(selectCartTotalAmount)
    const shippingAddress = useSelector(selectShippingAddress)

    useEffect(() => {
        if (!stripe) {
            return
        }
        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret")
        if (!clientSecret) {
            return
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage("Payment succeeded!");
                    saveOrder();
                    break;
                case 'processing':
                    setMessage("Your payment is processing.");
                    break;
                case 'requires_payment_method':
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe])

    // Save order to Order History
    const saveOrder = () => {
        const today = new Date()
        const date = today.toDateString()
        const time = today.toLocaleTimeString()
        const orderConfig = {
            userID,
            userEmail,
            orderDate: date,
            orderTime: time,
            orderAmount: parseFloat(cartTotalAmount.toFixed(2)),
            orderStatus: "Commande enregistrée...",
            cartItems,
            userName: shippingAddress.formData.name,
            userPhone: shippingAddress.formData.phone,
            companyName: shippingAddress.formData.companyName,
            timeframe: shippingAddress.formData.timeframe,
            line1: shippingAddress.formData.line1,
            line2: "",
            city: shippingAddress.formData.city,
            country: "Belgique",
            createAt: Timestamp.now().toDate(),
            remarque: shippingAddress.formData.remarque,
            shippingAddress,
        }
        try {
            console.log(orderConfig)
            addDoc(collection(db, "orders"), orderConfig);
            dispatch(CLEAR_CART());
            toast.success("Commande sauvée");
            navigate("/checkout-success");
        } catch (error) {
            toast.error(error.message);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(null)

        if (!stripe || !elements) {
            return
        }

        setIsLoading(true)

        const confirmPayment = await stripe
            .confirmPayment({
                elements,
                confirmParams: {
                    return_url: "https://eshop.potaupho.be/checkout-success",
                },
                redirect: "if_required",
            })
            .then((result) => {
                if (result.error) {
                    toast.error(result.error.message);
                    setMessage(result.error.message);
                    return;
                }
                if (result.paymentIntent) {
                    if (result.paymentIntent.status === "succeeded") {
                        setIsLoading(false);
                        toast.success("Payement réussi");
                        saveOrder();
                    }
                }
            })
        setIsLoading(false)
    }

    return (
        <section>
            <div className={`container ${styles.checkout}`}>
                <h2>Validation</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <Card cardClass={styles.card}>
                            <CheckoutSummary />
                        </Card>
                    </div>
                    <div>
                        <Card cardClass={`${styles.card} ${styles.pay}`}>
                            <h3>Payement validation</h3>
                            <PaymentElement id={styles["payment-element"]} />
                            <button className={styles.button}
                                disabled={isLoading || !stripe || !elements}
                                id="submit">
                                <span id="button-text">
                                    {isLoading ? (<img src={spinnerImg} alt="Loading ..." style={{ width: "20px" }} />) : ("Payer maintenant")}
                                </span>
                            </button>
                            {message && <div id={styles["payment-message"]}>
                                {message}
                            </div>}
                        </Card>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default CheckoutForm