import React, { useState } from 'react'
import styles from './ProductFilter.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectProducts } from '../../../redux/slice/productSlice'
import { FILTER_BY_CATEGORY } from '../../../redux/slice/filterSlice'

const ProductFilter = () => {
  const [category, setCategory] = useState("Tout")
  const products = useSelector(selectProducts)

  const dispatch = useDispatch()

  const allCategories = [
    "Tout",
    ...new Set(products.map((product => product.category)))
  ]
  const filterProducts = (cat) => {
    setCategory(cat)
    dispatch(FILTER_BY_CATEGORY({products, category: cat}))
  }

  const clearFilters = () => {
    setCategory("Tout")
  }
  
  return (
    <div className={styles.filter}>
      <h4>Catégories</h4>
      <div className={styles.category}>
        { allCategories.map((cat, index) => {
          return (
            <button key={index} 
              type="button" 
              className={`${category}` === cat ? `${styles.active}` : null} 
              onClick={() => filterProducts(cat)}
              >
              &#8250; {cat}
            </button>
          )
        })}
      </div>
      <br />
      <button className='--btn --btn-success' onClick={ clearFilters }>Init Filtre</button>
    </div>
  )
}

export default ProductFilter