import React from 'react'
import Card from '../../card/Card'
import styles from './ProductItem.module.scss'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ADD_TO_CART, CALCUTLATE_TOTAL_QUANTITY } from '../../../redux/slice/cartSlice'

const ProductItem = ({ product, grid, id, name, price, desc, imageURL }) => {

  const dispatch = useDispatch()
  const shortenText = (text, nb) => {
    if (text.length > nb) {
      const shortText = text.substring(0, nb).concat("...")
      return shortText
    }
    return text
  }

  const addToCart = (product) => {
    dispatch(ADD_TO_CART(product))
    dispatch(CALCUTLATE_TOTAL_QUANTITY())
  }
  return (
    <Card cardClass={ grid ? `${styles.grid}`: `${styles.list}`}>
      <Link to={`/product-details/${id}`}>
        <div className={styles.img}>
          <img src={imageURL} alt={name} />
        </div>
      </Link>
      <div className={styles.content}>
        <div className={styles.details}>
          <p>{`${price.toFixed(2)} €`}</p>
          <h4>{shortenText(name, 18)}</h4>
        </div>
        {!grid && <p className={styles.desc}>{shortenText(desc, 100)}</p>}
        <button className='--btn --btn-success' onClick={() => addToCart(product)}>Ajouter au panier</button>
      </div>
  </Card>
  )
}

export default ProductItem