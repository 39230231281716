import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filteredProduct: []
}

const filterSlide = createSlice({
  name: "filter",
  initialState,
  reducers: {
    FILTER_BY_SEARCH(state, action) {
        const {products, search} = action.payload;
        const tempProducts = products.filter((product) => product.name.toLowerCase().includes(search.toLowerCase())
         || product.category.toLowerCase().includes(search.toLowerCase())
         || product.desc.toLowerCase().includes(search.toLowerCase()));
        state.filteredProduct = tempProducts;
    },
    SORT_PRODUCT(state, action) {
        const { products, sort } = action.payload;
        let tempProducts = []
        if (sort === "latest") {
            tempProducts = products
        };
        if (sort === "name") {
            tempProducts = products.slice().sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
        };
        if (sort === "category") {
            tempProducts = products.slice().sort((a, b) => {
                return a.category.localeCompare(b.category)
            })
        };

        state.filteredProduct = tempProducts
    },
    FILTER_BY_CATEGORY(state, action) {
        const { products, category } = action.payload
        let tempProducts = []
        if (category === "Tout") {
            tempProducts = products
        } else {
            tempProducts = products.filter((product) => product.category === category)
        }
        state.filteredProduct = tempProducts
    },
  }
});

export const { FILTER_BY_SEARCH, SORT_PRODUCT, FILTER_BY_CATEGORY } = filterSlide.actions
export const selectFilteredProduct = (state) => state.filter.filteredProduct 
export default filterSlide.reducer