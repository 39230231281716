import React, { useState } from 'react'
import styles from './Pagination.module.scss'

const Pagination = ({currentPage, setCurrentPage, productsPerPage, totalProducts}) => {
    const pageNumbers = []
    const totalPages = totalProducts / productsPerPage
    // Limit the page number shown
    const [pageNumberLimit] = useState(5)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

    // Paginate
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    // Go to next page
    const paginateSuiv = () => {
        setCurrentPage(currentPage + 1)
        // Show next set pageNumbers
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
        }
    }

    // Go to prev page
    const paginatePrec = () => {
        setCurrentPage(currentPage - 1)
        // Show preview set pageNumbers
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
    }

    for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++){
        pageNumbers.push(i)
    }

  return (
    <div className={styles.pagination}>
        <li onClick={() => paginatePrec()} className={currentPage === pageNumbers[0] ? `${styles.hidden}` : null}>Préc</li>

        {pageNumbers.map((number) => {
            if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
                return (
                    <li key={number} onClick={() => paginate(number)} className={currentPage === number ? `${styles.active}` : null}>{number}</li>
                )
            }
        })}
        <li onClick={() => paginateSuiv()} className={currentPage === pageNumbers[pageNumbers.length - 1] ? `${styles.hidden}` : null}>Suiv</li>
        <p>
            <b className={styles.page}>{`page ${currentPage}`}</b>
            <span> / </span>
            <b>{`${Math.ceil(totalPages)}`}</b>
        </p>
    </div>
  )
}

export default Pagination