import React, { useEffect, useState } from 'react'
import styles from './ReviewProducts.module.scss'
import { useSelector } from 'react-redux'
import { selectUserID, selectUserName } from '../../redux/slice/authSlice'
import { Link, useParams } from 'react-router-dom'
import Card from '../card/Card'
import StarsRating from 'react-star-rate'
import useFetchDocument from '../../customHooks/useFetchDocument'
import { Timestamp, addDoc, collection } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { toast } from 'react-toastify'
import spinnerImg from "../../assets/spinner.jpg";

const ReviewProducts = () => {
  const [rate, setRate] = useState(0)
  const [review, setReview] = useState("")
  const [product, setProduct] = useState(null);
  const { id } = useParams()
  const { document } = useFetchDocument("products", id);
  const userID = useSelector(selectUserID)
  const userName = useSelector(selectUserName)

  useEffect(() => {
    setProduct(document);
  }, [document]);

  const submitReview = (e) => {
     e.preventDefault();

    const today = new Date();
    const date = today.toDateString();
    const reviewConfig = {
      userID,
      userName,
      productID: id,
      rate,
      review,
      reviewDate: date,
      createdAt: Timestamp.now().toDate(),
    };
    try {
      addDoc(collection(db, "reviews"), reviewConfig);
      toast.success("Review submitted successfully");
      setRate(0);
      setReview("");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <section>
      <div className={`container ${styles.review}`}>
        <h2>Commentaire sur le produit</h2>
        {product === null ? (
          <img src={spinnerImg} alt="Loading..." style={{width: "50px"}}/>
        ) : (
          <>
            <p>
              <b>Nom produit: </b> {product.name}
          </p>
          <img src={product.imageURL} alt={product.name} style={{width: "100px"}}/>
          </>
        )}
        <Card cardClass={styles.card}>
          <form on onSubmit={(e) => submitReview(e)}>
            <label>Score:</label>
            <StarsRating
              value={rate}
              onChange={rate => {
                setRate(rate)
              }}
             />
             <label>Commentaire:</label>
             <textarea value={review} required onChange={(e) => setReview(e.target.value)} cols="30" rows="10"></textarea>
            <Link to={'/order-history'}>
              <button type='submit' className='--btn --btn-success'>Ajouter commentaire</button>
            </Link>
          </form>
        </Card>
      </div>
    </section>
  )
}

export default ReviewProducts