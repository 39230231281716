import React, { useEffect, useState } from 'react'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { sliderData } from './slider-data'
import "./Slider.scss"

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const slideLenght = sliderData.length;
    const nextSlide = () => {
        setCurrentSlide(currentSlide === slideLenght - 1 ? 0 : currentSlide + 1);
    };
    const prevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? slideLenght - 1 : currentSlide -1 )
    };
    const autoScroll = true
    let slideInterval;
    let intervalTime = 5000;

    useEffect(() => {
        setCurrentSlide(0)
    }, [])
    // function auto() {
    //     slideInterval = setInterval(nextSlide, intervalTime)
    // }
    useEffect(() => {
        if (autoScroll) {
            const auto = () => {
                slideInterval = setInterval(nextSlide, intervalTime)
            }
        auto()
        }
        return () => clearInterval(slideInterval)
    }, [currentSlide, slideInterval, autoScroll])
  return (
    <div className='slider'>
        <AiOutlineArrowLeft className='arrow prev' onClick={ prevSlide }/>
        <AiOutlineArrowRight className='arrow next' onClick={ nextSlide }/>

        { sliderData.map((slide, index) => {
            const { image, heading } = slide
            return (
                <div key={index} className={index === currentSlide ? "slide current" : "slide"}>
                    {index === currentSlide && (
                        <>
                            <img src={image} alt="slide" />
                            <div className='content'>
                                <h2>{heading}</h2>
                                <hr />
                                    <a href='#product' className='--btn --btn-success'>
                                        Commandez
                                    </a>
                            </div>
                        </>
                    )}
                </div>
            )
        })}
    </div>
  )
}

export default Slider