export const sliderData = [
  {
    image: "https://firebasestorage.googleapis.com/v0/b/ecompap-2fe56.appspot.com/o/ecompap%2FPhoBoeufSmall.jpg?alt=media&token=097962f5-efea-4287-930b-768d56d82780",
    heading: "Phở boeuf",
  //   desc: "Up to 30% off on all onsale proucts.",
  },
  {
    image: "https://firebasestorage.googleapis.com/v0/b/ecompap-2fe56.appspot.com/o/ecompap%2FBoBunSmall.jpg?alt=media&token=51805312-4b52-4a83-8ce2-5b5a0d5e7c60",
    heading: "Bò Bún",
    // desc: "Up to 30% off on all onsale proucts.",
  },
  {
    image: "https://firebasestorage.googleapis.com/v0/b/ecompap-2fe56.appspot.com/o/ecompap%2FNemsPouletSmall.jpg?alt=media&token=1f336d3d-3be2-4e66-a0cf-87b2fc461a03",
    heading: "Nems Poulet",
    // desc: "Up to 30% off on all onsale proucts.",
  },
  {
    image: "https://firebasestorage.googleapis.com/v0/b/ecompap-2fe56.appspot.com/o/ecompap%2Fthe_maisonSmall.jpg?alt=media&token=916e1ba4-2fbb-4db1-b983-d7be35c27014",
    heading: "Thé maison",
    // desc: "Up to 30% off on all onsale proucts.",
  },
];
