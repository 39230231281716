import React, { useEffect, useState } from 'react'
import styles from './ProductList.module.scss'
import { BsFillGridFill } from 'react-icons/bs'
import { FaListAlt } from 'react-icons/fa'
import Search from '../../search/Search'
import ProductItem from '../productIem/ProductItem'
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_BY_SEARCH, SORT_PRODUCT, selectFilteredProduct } from '../../../redux/slice/filterSlice'
import Pagination from '../../pagination/Pagination'

const ProductList = ({ products }) => {
  const [grid, setGrid] = useState(true)
  const [search, setSearch] = useState("")
  const [sort, setSort] = useState("latest")
  const filteredProducts = useSelector(selectFilteredProduct)
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [productsPerPage] = useState(9)
  // Get current Products
  const indexOfLastProduct = currentPage * productsPerPage
  const indexOfFirsProduct = indexOfLastProduct - productsPerPage
  const currentProducts = filteredProducts.slice(indexOfFirsProduct, indexOfLastProduct)
  
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({products, search}))
  }, [dispatch, products, search])

  useEffect(() => {
    dispatch(SORT_PRODUCT({products, sort}))
  }, [dispatch, products, sort])

  return (
    <div className={styles["product-list"]} id="product">
      <div className={ styles.top }>
        <div className={styles.icons}>
          <BsFillGridFill size={22} color="##72b678" onClick={() => setGrid(true)}/>
          <FaListAlt size={24} color="#005335" onClick={() => setGrid(false)} />
          <p>
            <b>{filteredProducts.length}</b> Produits trouvé.
          </p>
        </div>
       {/* <div>
          <Search value={search} onChange={(e) => setSearch(e.target.value)}/>
        </div>
        <div className={styles.sort}>
          <label>Trié par: </label>
          <select value={sort} onChange={(e) => setSort(e.target.value)}>
            <option value="latest">Dernier</option>
            <option value="name">Nom</option>
            <option value="category">Categorie</option>
          </select>
        </div>*/}
      </div>
      <div className={grid ? `${styles.grid}` : `${styles.list}`}>
        { products.lenght === 0 ? (
          <p>Produit non trouvé.</p>
        ) : (
          <>
            { currentProducts.map((product) => {
              return (
                <div key={product.id}>
                  <ProductItem { ...product } grid={grid} product={product} />
                </div>
              )
            })}
          </>
          )
        }
      <Pagination 
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        productsPerPage={productsPerPage}
        totalProducts={filteredProducts.length}
      />
      </div>
    </div>
  )
}

export default ProductList
