import React, { useRef } from 'react'
import styles from './Conditions.module.scss'
import Card from '../card/Card'

const Conditions = () => {
  const form = useRef()
  return (
    <section>
      <div className={`container ${styles.contact}`}>
        <h2>Conditions de Vente pour le site eshop.potaupho.be</h2>
            <Card cardClass={styles.card2}>
              <h4>1. Acceptation des Conditions</h4>
              <p>En utilisant notre site eshop.potaupho.be pour passer une commande de livraison, vous acceptez pleinement et sans réserve les présentes conditions de vente. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre service de commande en ligne.</p>
              <br/>
              <h4>2. Commandes</h4>
              <p>Les commandes peuvent être passées via notre site web ou notre application mobile avant 11h00 le jour même. En passant une commande, vous confirmez que toutes les informations fournies sont exactes et complètes. Nous nous réservons le droit d'accepter ou de refuser toute commande à notre entière discrétion.</p>
              <br/>
              <h4>3. Disponibilité des Produits</h4>
              <p>Toutes les commandes sont soumises à la disponibilité des produits. En cas d'indisponibilité d'un produit après la passation de la commande, nous vous contacterons pour vous en informer et vous proposerons une solution de remplacement ou un remboursement.</p>
              <br/>
              <h4>4. Prix et Paiement</h4>
              <p>Les prix affichés sur notre site sont en euros et incluent la TVA applicable. Les frais de livraison sont à notre charge. Le paiement peut être effectué en ligne par bancontact, carte de crédit ou tout autre moyen de paiement accepté par notre plateforme.</p>
              <br/>
              <h4>5. Livraison</h4>
              <p>Nous nous efforçons de livrer les commandes dans les délais convenus. Cependant, les délais de livraison peuvent varier en fonction de la disponibilité des livreurs et des conditions de circulation. Nous ne pouvons être tenus responsables des retards de livraison indépendants de notre volonté.</p>
              <br/>
              <h4>6. Annulations et Modifications</h4>
              <p>Les annulations ou modifications de commande doivent être effectuées le plus tôt possible en nous contactant directement. Les annulations ou modifications tardives peuvent ne pas être acceptées, en particulier une fois que la commande a été préparée ou est en cours de livraison.</p>
              <br/>
              <h4>7. Réclamations et Retours</h4>
              <p>Si vous n'êtes pas satisfait de votre commande pour quelque raison que ce soit, veuillez nous contacter dès que possible pour que nous puissions résoudre le problème. Les retours ne sont pas possible et les remboursements seront traités conformément à notre politique de remboursment.</p>
              <br/>
              <h4>8. Données Personnelles</h4>
              <p>En passant une commande, vous consentez à ce que vos données personnelles soient utilisées aux fins de traitement de la commande et de livraison. Nous nous engageons à protéger la confidentialité de vos informations conformément à notre politique de confidentialité.</p>
              <br/>
              <h4>9. Modification des Conditions</h4>
              <p>Nous nous réservons le droit de modifier les présentes conditions de vente à tout moment. Les modifications seront effectives dès leur publication sur notre site web. Il est de votre responsabilité de consulter régulièrement ces conditions pour rester informé des changements.</p>
              <p>En utilisant notre service de commande en ligne, vous reconnaissez avoir lu, compris et accepté les présentes conditions de vente. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter. Merci de votre confiance et de votre soutien.</p>
            </Card>
      </div>
    </section>
  )
}

export default Conditions
